<template>
  <!-- 住院费用查询 -->
  <div class="hospitalization-expenses">
    <!-- 切换就诊人 -->
    <SwitchPeople />
    <Scroll
      class="region-scroll"
      ref="scroll"
    >
      <div v-if="Object.keys(hospitalizationList).length != 0">
        <div class="details-card">
          <div class="details-middle in-size">
            <div>
              <span>状态</span>
              <span style="color:#d64e47">{{hospitalizationList.stat == undefined || hospitalizationList.stat == null || hospitalizationList.stat == '' ? '---' : hospitalizationList.stat}}</span>
            </div>
            <div>
              <span>住院号</span>
              <span>{{hospitalizationList.in_pid == undefined || hospitalizationList.in_pid == null || hospitalizationList.in_pid == '' ? '---' : hospitalizationList.in_pid}}</span>
            </div>
            <div>
              <span>病区</span>
              <span>{{hospitalizationList.area == undefined || hospitalizationList.area == null || hospitalizationList.area == '' ? '---' : hospitalizationList.area}}</span>
            </div>
            <div>
              <span>病床</span>
              <span>{{hospitalizationList.bed == undefined || hospitalizationList.bed == null || hospitalizationList.bed == '' ? '---' : hospitalizationList.bed}}</span>
            </div>
            <div>
              <span style="flex:0.3">入院天数</span>
              <span style="flex:0.4">{{hospitalizationList.in_date}}</span>
              <span style="flex:0.3;white-space:nowrap;color: #8a8488;">{{`已入院${times}天`}}</span>
            </div>
          </div>
        </div>
        <div class="details-money in-size">
          <div>
            <span class="text-color">费用总额</span>
            <span class="sum-color">{{sum == undefined || sum == null || sum == '' ? '---' : sum}}元</span>
          </div>
          <div
            v-for="(item,index) in cost"
            :key="index"
          >
            <span>{{item.item_type == undefined || item.item_type == null || item.item_type == '' ? '---' : item.item_type}}</span>
            <span>{{item.total == undefined || item.total == null || item.total == '' ? '---' : item.total}}元</span>
          </div>
        </div>
      </div>
      <div v-else>
        <van-empty description="无住院记录" />
      </div>
    </Scroll>
  </div>
</template>

<script>
import Scroll from '@/components/content/scroll/BScroll'
import { jd2hisInpatientCost, inpatientIdcard, } from '@/network/service'
import SwitchPeople from '@/components/common/switchPeople/switchPeople'
import { mapState } from 'vuex'
export default {
  name: 'hospitalizationExpenses',
  components: {
    Scroll,
    SwitchPeople
  },
  data () {
    return {
      //费用总计
      cost: [],
      //住院信息
      hospitalizationList: {},
    }
  },
  computed: {
    ...mapState(['seq', 'cardList']),
    //住院时间
    times () {
      let time = new Date().getTime() - new Date(this.hospitalizationList.in_date).getTime()
      return Math.ceil(time / (1000 * 3600 * 24));
    },
    //总金额
    sum () {
      return this.cost.reduce((total, item) => {
        return total + Number(item.total)
      }, 0)
    }
  },
  mounted () {
    this.inpatientIdcardPost()
  },
  methods: {
    //住院信息
    async inpatientIdcardPost () {
      let res = await inpatientIdcard({
        seq: this.seq,
        id_no: this.cardList[0].idNo,
        name: this.cardList[0].name,
        phone: this.cardList[0].phone,
      });
      if (res.code == '0') {
        this.hospitalizationList = res.data.root.body;
        //住院费用查询
        let cost = await jd2hisInpatientCost({
          seq: this.seq,
          in_pid: this.hospitalizationList.in_pid,
        });
        if (cost.code == '0') {
          //金额
          this.cost = cost.data.root.body.detail;
          this.$nextTick(() => {
            this.$refs.scroll.refresh();
          })
        } else {
          this.$toast(cost.msg);
        }
      } else {
        this.$toast(res.msg);
      }

    }
  },
}
</script>

<style lang='less' scoped>
@fff: #fff;
.icon {
  font-size: 1.2rem;
}
.hospitalization-expenses {
  background-color: #f4f4f4;
  height: 100vh;
  .region-scroll {
    height: 100vh;
    overflow: hidden;
    .details-card {
      box-sizing: border-box;
      line-height: 1.8rem;
      background-color: @fff;
      padding: 1rem;
      border-top: 1rem solid #f4f4f4;
      .details-top {
        display: flex;
        justify-content: space-between;
        padding: 0 0.5rem;
        padding-bottom: 0.5rem;
      }
      .details-middle {
        padding: 0.5rem;
        div {
          display: flex;
          span:first-child {
            flex: 0.3;
            color: #8a8488;
          }
          span:last-child {
            flex: 0.7;
          }
        }
      }
    }
    .details-money {
      background-color: @fff;
      margin-top: 1rem;
      .text-color {
        font-weight: 600;
        color: black !important;
        font-size: 1.2rem;
      }
      .sum-color {
        font-weight: 600;
        color: #d64e47 !important;
        font-size: 1.2rem;
      }
      div {
        display: flex;
        justify-content: space-between;
        line-height: 3rem;
        border-bottom: 1px solid #e7e6e6;
        padding: 0 1rem;
      }
    }
  }
}
</style>